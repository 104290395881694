import React from 'react';

import PropTypes from 'prop-types';

const Experience = props => (
  <article
    id="experience"
    className={`${props.article === 'experience' ? 'active' : ''} ${
      props.articleTimeout ? 'timeout' : ''
    }`}
    style={{ display: 'none' }}
  >
    <h2 className="major">Experience</h2>

    <div className="timeline">
      <div className="timeline-inner">
        <div className="name">
          <span className="date">September 2019 - Present</span>
          <h4>Front-End Developer – Stars Technologies </h4>
        </div>
        <div className="detail">
          <p>
            Developed a Rational Account Management App. Restructured entire UI
          </p>
        </div>
      </div>
      <div className="timeline-inner ">
        <div className="name switched">
          <span className="date">January 2019 - April 2019</span>
          <h4>Full-Stack Developer – Candy Digital </h4>
        </div>
        <div className="detail">
          <p>Developed a dynamic banner generator (Capstone Project)</p>
        </div>
      </div>
      <div className="timeline-inner">
        <div className="name">
          <span className="date">October 2017 - April 2018</span>
          <h4>Full-Stack Developer – Damso </h4>
        </div>
        <div className="detail">
          <p>
            Developed a community website for Korean living in Canada as a full
            stack developer
          </p>
        </div>
      </div>
      <div className="timeline-inner ">
        <div className="name switched">
          <span className="date">January 2015 - February 2016</span>
          <h4>Full-Stack Developer – Hyundai U & I </h4>
        </div>
        <div className="detail">
          <p>
            Developed and maintained a website for legal management system as a
            full stack developer
          </p>
        </div>
      </div>
    </div>
    <div
      className="close"
      onClick={() => {
        props.onCloseArticle();
      }}
    />
  </article>
);

Experience.propTypes = {
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
};

export default Experience;
