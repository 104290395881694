import React from 'react';

import PropTypes from 'prop-types';

const Intro = props => (
  <article
    id="intro"
    className={`${props.article === 'intro' ? 'active' : ''} ${
      props.articleTimeout ? 'timeout' : ''
    }`}
    style={{ display: 'none' }}
  >
    <h2 className="major">Intro</h2>
    <div className="projectCopy">
      <h3>About (me)</h3>
      <p>
        The name’s DAVID KIM. I'm a web developer based in Toronto, Canada. I am
        a tireless seeker of knowledge and have passion in software developing
        and working in a dynamic environment.
      </p>

      <h3>Education</h3>
      <p className="orange">
        Post Graduate Degree
        <br />
        Information Technology Solution – 2017 – 2019
      </p>
      <p className="orange">
        Bachelor's degree
        <br />
        Computer Science – 2008 – 2015
      </p>
      <p>
        I am a graduate of the Computer Science program at the Handong
        university and the Information Technology Solution program at the Humber
        college.
      </p>
    </div>
    <div
      className="close"
      onClick={() => {
        props.onCloseArticle();
      }}
    />
  </article>
);

Intro.propTypes = {
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
};

export default Intro;
