import React from 'react';
import PropTypes from 'prop-types';

import Intro from './Intro';
import Skills from './Skills';
import Contact from './Contact';
import Experience from './Experience';
import Projects from './Projects';

class Main extends React.Component {
  render() {
    const { handleModalOpen, handleModalClose } = this.props;

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <Intro
          article={this.props.article}
          articleTimeout={this.props.articleTimeout}
          onCloseArticle={this.props.onCloseArticle}
        />

        <Skills
          article={this.props.article}
          articleTimeout={this.props.articleTimeout}
          onCloseArticle={this.props.onCloseArticle}
        />

        <Experience
          article={this.props.article}
          articleTimeout={this.props.articleTimeout}
          onCloseArticle={this.props.onCloseArticle}
        />

        <Projects
          article={this.props.article}
          articleTimeout={this.props.articleTimeout}
          onCloseArticle={this.props.onCloseArticle}
          onModalOpen={handleModalOpen}
          onModalClose={handleModalClose}
        />

        <Contact
          article={this.props.article}
          articleTimeout={this.props.articleTimeout}
          onCloseArticle={this.props.onCloseArticle}
        />
      </div>
    );
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
};

export default Main;
