import React from 'react';

import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import Image from './Image';

class Projects extends React.Component {
  state = {
    tabs: [
      {
        title: 'ALL',
        filter: '*',
      },
      {
        title: 'REACT',
        filter: 'react',
      },
      {
        title: 'GRAPHQL',
        filter: 'graphql',
      },
      {
        title: 'HACKATHON',
        filter: 'hackathon',
      },
      {
        title: 'HTML/CSS',
        filter: 'html',
      },
    ],
    current: '*',
  };

  handleTabClick = current => {
    this.setState({ current });
  };

  renderTabs = () =>
    this.state.tabs.map(tab => (
      <li
        className={this.state.current === tab.filter ? 'current' : ''}
        key={tab.filter}
        onClick={() => this.handleTabClick(tab.filter)}
      >
        <div data-filter={tab.filter}>{tab.title}</div>
      </li>
    ));

  renderProjects = projects =>
    projects.map((project, index) => {
      if (
        project.category === this.state.current ||
        this.state.current === '*'
      ) {
        return (
          <div className="col-3" key={index}>
            <div
              className="design hentry 6u 12u$(xsmall)"
              style={{
                width: '100%',
              }}
            >
              <div className="image-container">
                <div
                  className="media-box"
                  onClick={() => this.props.onModalOpen(project)}
                >
                  <Image
                    alt={project.title}
                    filename={`${project.image}_thumb`}
                  />
                  <div className="mask" />
                </div>
              </div>
              <h4>{project.title}</h4>
              <p className="category">{project.short_description}</p>
            </div>
          </div>
        );
      } else return null;
    });

  render() {
    return (
      <article
        id="projects"
        className={`${this.props.article === 'projects' ? 'active' : ''} ${
          this.props.articleTimeout ? 'timeout' : ''
        }`}
        style={{ display: 'none' }}
      >
        <h2 className="major">Projects</h2>
        <ul id="filters">{this.renderTabs()}</ul>
        <div className="portfolio-items" style={{ position: 'relative' }}>
          <StaticQuery
            query={graphql`
              query SiteProjectsQuery {
                site {
                  siteMetadata {
                    projects {
                      title
                      short_description
                      long_description
                      client
                      url
                      github
                      category
                      image
                      technology
                    }
                  }
                }
              }
            `}
            render={data => (
              <div className="grid-wrapper">
                {this.renderProjects(data.site.siteMetadata.projects)}
              </div>
            )}
          />
        </div>
        <div
          className="close"
          onClick={() => {
            this.props.onCloseArticle();
          }}
        />
      </article>
    );
  }
}

Projects.propTypes = {
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
};

export default Projects;

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 1000) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;
