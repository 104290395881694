import React from 'react';

import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

const renderTabs = metaData => (
  <Tabs>
    <TabList>
      {metaData.tags.map(tag => (
        <Tab key={tag}>{tag}</Tab>
      ))}
    </TabList>

    {metaData.tags.map(tag => renderSkillContent(metaData.skills, tag))}
  </Tabs>
);

const renderSkillContent = (skills, tag) => (
  <TabPanel key={tag}>
    {skills
      .filter(skill => skill.tag === tag)
      .map((skill, index) => (
        <div className="nectar-progress-bar" key={index}>
          <p>{skill.title}</p>
          <div className="bar-wrap">
            <span
              className="accent-color"
              data-width={skill.percent}
              style={{ width: `${skill.percent}%` }}
            >
              {' '}
              <strong style={{ opacity: 1 }}>
                <i>{skill.percent}</i>%
              </strong>{' '}
            </span>
          </div>
        </div>
      ))}
  </TabPanel>
);

const Skills = props => (
  <article
    id="skills"
    className={`${props.article === 'skills' ? 'active' : ''} ${
      props.articleTimeout ? 'timeout' : ''
    }`}
    style={{ display: 'none' }}
  >
    <h2 className="major">Skills</h2>
    <p>
      I’m constantly learning web technologies, currently playing around with
      React and Node.js. I’m a skilled in many areas of development. Some of my
      skills include:
    </p>
    <div className="skills-article wpb_wrapper completed">
      <StaticQuery
        query={graphql`
          query SiteSkillsQuery {
            site {
              siteMetadata {
                skills {
                  title
                  percent
                  tag
                }
                tags
              }
            }
          }
        `}
        render={data => <>{renderTabs(data.site.siteMetadata)}</>}
      />
    </div>
    <div
      className="close"
      onClick={() => {
        props.onCloseArticle();
      }}
    />
  </article>
);

Skills.propTypes = {
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
};

export default Skills;
