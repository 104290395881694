import React from 'react';
import PropTypes from 'prop-types';

const menuItem = ['intro', 'skills', 'experience', 'projects', 'contact'];
const renderMenu = props =>
  menuItem.map(item => (
    <li key={item}>
      <a
        onClick={() => {
          props.onOpenArticle(item);
        }}
      >
        {item}
      </a>
    </li>
  ));
const Header = props => (
  <header id="header" style={props.timeout ? { display: 'none' } : {}}>
    <div className="content">
      <div className="inner">
        <h1>David Kim</h1>
        <p>web developer living in Toronto.</p>
      </div>
    </div>
    <nav>
      <ul>{renderMenu(props)}</ul>
    </nav>
  </header>
);

Header.propTypes = {
  onOpenArticle: PropTypes.func,
  timeout: PropTypes.bool,
};

export default Header;
