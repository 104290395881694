import React from 'react';

import PropTypes from 'prop-types';

const Contact = props => (
  <article
    id="contact"
    className={`${props.article === 'contact' ? 'active' : ''} ${
      props.articleTimeout ? 'timeout' : ''
    }`}
    style={{ display: 'none' }}
  >
    <h2 className="major">Contact</h2>
    <form method="POST" action="https://formspree.io/david.ingook@gmail.com">
      <div className="field half first">
        <label htmlFor="name">Name</label>
        <input type="text" name="name" id="name" />
      </div>
      <div className="field half">
        <label htmlFor="email">Email</label>
        <input type="text" name="email" id="email" />
      </div>
      <div className="field">
        <label htmlFor="message">Message</label>
        <textarea name="message" id="message" rows="4" />
      </div>
      <ul className="actions">
        <li>
          <input type="submit" value="Send Message" className="special" />
        </li>
        <li>
          <input type="reset" value="Reset" />
        </li>
      </ul>
    </form>
    <ul className="icons">
      <li>
        <a
          href="https://www.linkedin.com/in/inkoog-kim/"
          className="icon fa-linkedin"
        >
          <span className="label">Linkedin</span>
        </a>
      </li>
      <li>
        <a href="https://github.com/goginim" className="icon fa-github">
          <span className="label">GitHub</span>
        </a>
      </li>
    </ul>
    <div
      className="close"
      onClick={() => {
        props.onCloseArticle();
      }}
    />
  </article>
);

Contact.propTypes = {
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
};

export default Contact;
